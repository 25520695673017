// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
// sections
import {
  AppContact,
  AppFollowUs,
  AppHighlights,
  AppLogo,
  AppNonProfit,
  AppRegistration,
  AppTrending,
} from '../sections/@dashboard/app';


export default function DashboardApp() {
  const theme = useTheme();

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={10}>
            <AppTrending title="Trending" />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <AppHighlights title="Highlights" />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppLogo title="SCVCA Logo" />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppRegistration title="Registration" subheader="Registration is now open" />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppContact title="Contact US" />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppFollowUs title="Follow Us" />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppNonProfit title="Non-Profit Organization" subheader="We are a registered Non-Profit Organization"/>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
