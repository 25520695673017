import PropTypes from 'prop-types';
import { Box, Card, CardHeader, Stack, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

ContactCard.propTypes = {
  subheader: PropTypes.string,
  title: PropTypes.string,
};

export default function ContactCard({ title, subheader, other }) {
  return (
    <Card sx={{ background: 'linear-gradient(45deg, rgb(237 235 227), transparent)' }} {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Stack direction="column" alignItems="left" spacing={2} paddingLeft>
        <Box sx={{ width: '100%', display: 'flex', paddingTop: '14px', paddingBottom: '14px', paddingLeft: '14px' }}>
          <EmailIcon/>
          <Typography variant="body1" align="center" sx={{paddingLeft: '14px' }}>
            contact@scvcricket.org
          </Typography>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', paddingBottom: '14px', paddingLeft: '14px' }}>
          <PhoneIcon/>
          <Typography variant="body1" align="center" sx={{paddingLeft: '14px' }}>
            747-300-5272
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
}
