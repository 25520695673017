import { faker } from '@faker-js/faker';

const posts =
[
  {
    id: faker.datatype.uuid(),
    cover: '/static/news/USA_cricket.jpeg',
    title: 'USA CRICKET YOUTH ZONAL TOURNAMENTS',
    url: 'https://www.usacricket.org/media-release/usa-cricket-youth-zonal-tournaments-2022-dates-announced/',
    createdAt: 'September 15, 2022',
  },
  {
    id: faker.datatype.uuid(),
    cover: '/static/news/USA_cricket1.jpg',
    title: 'PLAYER REGISTRATION - USA CRICKET',
    url: 'https://www.usacricket.org/player-registration/',
    createdAt: 'Jan 01, 2022',
  },
  {
    id: faker.datatype.uuid(),
    cover: '/static/news/USA_cricket2.jpg',
    title: 'Cricket, mainstream in the US?',
    url: 'https://www.bbc.com/sport/cricket/54721840/',
    createdAt: '29 October, 2020',
  },
]

export default posts;
