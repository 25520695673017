
// material
import { Card, CardHeader, Container, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ContactCard from '../components/ContactCard';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function Contact() {
  return (
    <Page title="Contact Us">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Contact US
          </Typography>
        </Stack>
        <ContactCard title='Email us at'/>
      </Container>
    </Page>
  );
}
