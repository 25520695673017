import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardHeader, Stack } from '@mui/material';
import Iconify from '../../../components/Iconify';

AppRegistration.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function AppRegistration({ title, subheader, other}) {

  return (
    <Card sx={{ background: 'linear-gradient(200deg, #c5bc8f, transparent)' }} {...other}>
      <CardHeader title={title} subheader={subheader} />
        <Stack direction="row" alignItems="center" spacing={2}>
         <Box sx={{padding:'24px'}}>
          <Button
            variant="outlined"
            to="/register" component={RouterLink}
            startIcon={<Iconify icon="eva:person-add-fill" width={20} height={20} />}
          >
             Register
         </Button>
         </Box>
      </Stack>
    </Card>
  );
}
