import { Box, Card, CardHeader, Container, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import products from '../data/photos';
import Page from '../components/Page';

export default function AboutUS() {
  const ProductImgStyle = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
  });

  return (
    <Page title="Photos and Videos">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Photos/Videos
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          {products.map((product) => {
            const { imgSrc } = product;
            return (
              <Grid key={product.id} item xs={12} sm={6} md={6}>
                <Card>
                  <Box sx={{ pt: '100%', position: 'relative' }}>
                    <ProductImgStyle src={imgSrc} />
                  </Box>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Page>
  );
}
