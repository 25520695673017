// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'home',
    path: '/app',
    icon: getIcon('eva:home-fill'),
  },
  {
    title: 'register',
    path: '/register',
    icon: getIcon('eva:person-add-fill'),
  },
  {
    title: 'photos/videos',
    path: '/photos',
    icon: getIcon('ic:round-monochrome-photos'),
  },
  {
    title: 'news',
    path: '/news',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'contact',
    path: '/contact',
    icon: getIcon('ic:round-contact-page'),
  },
  {
    title: 'tournament',
    path: '/tournament',
    icon: getIcon('ic:round-sports-cricket'),
  },
  {
    title: 'our story',
    path: '/aboutus',
    icon: getIcon('fluent:history-16-filled'),
  },
];

export default navConfig;
