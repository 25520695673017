import { useCallback } from 'react';
import { Box, Button, Card, CardHeader, Container, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { faker } from '@faker-js/faker';
import CirckeBullet from '@mui/icons-material/Circle';
import Iconify from '../components/Iconify';
import Page from '../components/Page';

export default function Register() {
  const openWaiver = useCallback(() => {
    window.open(
      'https://form.jotform.com/222431269431147',
      '_blank',
      'noopener noreferrer'
    );
  }, []);

  const openRegistration = useCallback(() => {
    window.open(
      'https://form.jotform.com/222611273495152',
      '_blank',
      'noopener noreferrer'
    );
  }, []);

  const openJersey = useCallback(() => {
    window.open(
      'https://form.jotform.com/223296663913059',
      '_blank',
      'noopener noreferrer'
    );
  }, []);

  const feeDetails = [
    'Annual Registration Fees: $125 (Siblings: $100)',
    'Monthly Fees: $50 (Siblings: $35)',    
  ];
  const paymentOptions = [
    'Quarterly: $150 / Siblings: $105',
    'Half Yearly: $275 / Siblings: $185 ($25 discount)',
    'Yearly: $550 / Siblings: $370 ($50 discount)',
    ];

  return (
    <Page title="Register">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Registration
          </Typography>
        </Stack>
        <Card>
          <CardHeader title="Please complete both the forms for each child:" />
          <Stack direction="row" alignItems="center" spacing={2}>            
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '24px' }}>
              <Button
                variant="outlined"
                onClick={openRegistration}
                startIcon={<Iconify icon="clarity:form-line" width={20} height={20} />}
              >
                Registration
              </Button>
            </Box>
          </Stack>
        </Card>
      </Container>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between"  mt={5} mb={1}>
          <Typography variant="h4" gutterBottom>
            Jersey
          </Typography>
        </Stack>
        <Card>
          <CardHeader title="Please enter jersey related details for each child: " />
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '24px' }}>
              <Button
                variant="outlined"
                onClick={openJersey}
                startIcon={<Iconify icon="clarity:form-line" width={20} height={20} />}
              >
                Jersey Form
              </Button>
            </Box>
          </Stack>
        </Card>
      </Container>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between"  mt={5} mb={1}>
          <Typography variant="h4" gutterBottom>
            Fee Details
          </Typography>
        </Stack>
        <Card>
        <CardHeader title={'2024 fee details effective Jan 1st, 2024'} />
          {feeDetails.map((fees) => (
            <List key={faker.datatype.uuid()} disablePadding>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <CirckeBullet />
                  </ListItemIcon>
                  <ListItemText primary={fees} />
                </ListItemButton>
              </ListItem>
            </List>
          ))}
          <CardHeader title={'Payment Options'} />
          {paymentOptions.map((payment) => (
            <List key={faker.datatype.uuid()} disablePadding>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <CirckeBullet />
                  </ListItemIcon>
                  <ListItemText primary={payment} />
                </ListItemButton>
              </ListItem>
            </List>
          ))}
          <CardHeader title={'zelle: scvcricketacademy@gmail.com'} />
          <br/>          
        </Card>
      </Container>
    </Page>
  );
}
