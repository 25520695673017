import { Card, CardHeader, Container, Stack, Typography } from '@mui/material';
import Page from '../components/Page';

export default function AboutUS() {
  return (
    <Page title="Our Story">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Our Story
          </Typography>
        </Stack>
        <Card>
          <CardHeader title="coming soon" sx={{ paddingBottom: '50px' }} />
        </Card>
      </Container>
    </Page>
  );
}
