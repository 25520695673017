import PropTypes from 'prop-types';
import { Card, CardHeader } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarBorder from '@mui/icons-material/StarBorder';
import { faker } from '@faker-js/faker';

AppHighlights.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function AppHighlights({ title, subheader, other }) {
  const highlights = [
    'Weekday Coaching & Weekend Games',
    'Practice & Game Jerseys',
    'Cricket Tournaments',
    'Boys & Girls, Age 7+',
    '$50 per month, sibling discounts',
    'First Month Free/Try out',
  ];

  return (
    <Card sx={{ background: 'linear-gradient(45deg, #e6e9d8, transparent)' }} {...other}>
      <CardHeader title={title} subheader={subheader} />
      {highlights.map((highlight) => (
        <List key={faker.datatype.uuid()} disablePadding>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary={highlight} />
            </ListItemButton>
          </ListItem>
        </List>
      ))}
    </Card>
  );
}
