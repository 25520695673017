import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardHeader, Stack } from '@mui/material';
import Iconify from '../../../components/Iconify';

AppFollowUs.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function AppFollowUs({ title, subheader, other}) {
  const openFacebook = useCallback(() => {
    window.open(
      'https://www.facebook.com/Santa-Clarita-Valley-Cricket-Academy-108800171965624/',
      '_blank',
      'noopener noreferrer'
    );
  }, []);

  return (
    <Card sx={{background: 'linear-gradient(45deg, #e5dcd4, transparent)'}} {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '24px' }}>
          <Button
            variant="outlined"
            onClick={openFacebook}
            startIcon={<Iconify icon="eva:facebook-fill" width={20} height={20} />}
          >
            facebook
          </Button>
        </Box>
      </Stack>
    </Card>
  );
}
