import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardHeader, Stack, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import Logo from '../../../components/Logo';

AppTrending.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function AppTrending({ title, subheader, chartColors, chartData, ...other }) {

  return (
    <Card {...other}>
      <Carousel navButtonsAlwaysVisible autoPlay>
        <Box sx={{background: 'linear-gradient(45deg, #916e0c, transparent)'}}>
          <Typography variant="h3" align="center" sx={{padding:"50px"}}>
            Santa Clarita Valley Cricket Academy Welcomes You!!!
          </Typography>
        </Box>
        <Box sx={{background: 'linear-gradient(180deg, #916e0c, transparent)'}}>
          <Typography variant="h3" align="center" sx={{padding:"50px"}}>
            Registration is now open
          </Typography>
        </Box>
        <Box sx={{background: 'linear-gradient(360deg, #916e0c, transparent)'}}>
          <Typography variant="h3" align="center" sx={{padding:"50px"}}>
            A great opportunity for kids to learn and play the world's 2nd most popular sport.
          </Typography>
        </Box>
      </Carousel>
    </Card>
  );
}
