import PropTypes from 'prop-types';
import { Box, Button, Card, CardHeader, Stack } from '@mui/material';


AppNonProfit.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function AppNonProfit({ title, subheader, other }) {
  return (
    <Card  sx={{ background: 'linear-gradient(45deg, rgb(255, 231, 217), transparent)' }} {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '24px' }}>
          <Box component="img" src="/static/non-profit.png" sx={{ width:'100px', height: 'auto' }} />
        </Box>
      </Stack>
    </Card>
  );
}
