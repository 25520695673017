import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';

//
import News from './pages/News';
import Contact from './pages/Contact';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Tournament from './pages/Tournament';
import Photos from './pages/Photos';
import AboutUs from './pages/AboutUs';
import DashboardApp from './pages/DashboardApp';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Navigate to="/app" /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'contact', element: <Contact /> },
        { path: 'news', element: <News /> },
        { path: 'photos', element: <Photos /> },
        { path: 'aboutus', element: <AboutUs /> },
        { path: 'register', element: <Register /> },
        { path: 'tournament', element: <Tournament /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
