import PropTypes from 'prop-types';
import { Box, Button, Card, CardHeader, Stack } from '@mui/material';
import Logo from '../../../components/Logo';

AppLogo.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function AppLogo({ title, subheader, other }) {
  return (
    <Card sx={{ background: 'linear-gradient(180deg, lightgrey, transparent)' }} {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '24px' }}>
          <Logo width="120px" />
        </Box>
      </Stack>
    </Card>
  );
}
