import PropTypes from 'prop-types';
import ContactCard from '../../../components/ContactCard';

AppContact.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

export default function AppContact({ title, subheader, other }) {
  return <ContactCard title={title} subheader={subheader} other={other} />;
}
