import { useCallback } from 'react';
import { Box, Button, Card, CardHeader, Container, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import Iconify from '../components/Iconify';
import Page from '../components/Page';

export default function Tournament() {
  const openRulesDoc = useCallback(() => {
    window.open(
      'https://docs.google.com/document/d/1Ru6wI8n3ZbdgKZ4sfNfogKtPV63W-2J_PksfdT008wI/edit?usp=sharing',
      '_blank',
      'noopener noreferrer'
    );
  }, []);

  const ProductImgStyle = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
  });

  return (
    <Page title="Santa Clarita Youth Cricket Tournament">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Tournament
          </Typography>
        </Stack>
        <Card>
          <CardHeader title={`SCVCA presents its first annual tournament (Feb 17th, 18th & 19th). Please click below for the tournament details and rules:`} />
          <Stack direction="row" alignItems="center" spacing={2}>            
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '24px' }}>
              <Button
                variant="outlined"
                onClick={openRulesDoc}
                startIcon={<Iconify icon="ic:baseline-article" width={20} height={20} />}
              >
                Tournament Details and Rules
              </Button>
            </Box>
          </Stack>
        </Card>
      </Container>
      <br/>     
      <Container>       
        <Grid container spacing={2}>          
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <Box sx={{ pt: '100%', position: 'relative' }}>
                <ProductImgStyle src='/static/tournament.png' />
              </Box>
            </Card>
          </Grid>            
        </Grid>
      </Container>
    </Page>
  );
}
